import React, { useEffect, useCallback } from "react";
import { Table, Row, Col } from "reactstrap";

import { connect } from "react-redux";
import { getFacturas, resetFacturas } from "../../redux/actions/facturas";

import PanelTitle from "../PanelTitle";
import Icon from "../../assets/img/facturas.svg";
import TableLoading from "../TableLoading";
import FacturasItem from "./FacturasItem";
import impa from "./FacturasItem";

const Facturas = ({
  facturas,
  loading,
  history,
  getFacturas,
  resetFacturas,
}) => {
  const getData = useCallback(async () => {
    const response = await getFacturas();
    if (response.status !== 200) {
      history.push("/panel");
    }
  }, [getFacturas, history]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    return () => resetFacturas();
  }, [resetFacturas]);

  return (
    <>
      <PanelTitle icon={Icon}>Facturas</PanelTitle>
      {loading ? (
        <div className="mt-5">
          <TableLoading />
        </div>
      ) : (
        <Row noGutters>
          <Col>
            {facturas.length === 0 ? (
              <h2 className="my-5">No se encontro nada.</h2>
            ) : (
              <>
                <Table responsive className="tables-dashboard mt-3 mt-sm-5">
                  <thead>
                    <tr>
                      <th scope="col">Fecha</th>
                      <th scope="col">Total</th>
                      <th scope="col">Estado</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {facturas.map((factura) => (
                      <FacturasItem key={factura.factura} {...factura} />
                    ))}
                    
                  </tbody>
                </Table>
              </>
            )}

            <Row noGutters>
              <Col>
                <button
                  onClick={() => {
                   //history.push("/preguntas-frecuentes?ff=true");
                    const win = window.open("/preguntas-frecuentes?ff=true", "_blank");
                    win.focus();
                  }}
                  className="link-descarga"
                >
                  Ver medios de pago
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  facturas: state.facturas.facturas,
  loading: state.facturas.loading,
});

export default connect(mapStateToProps, {
  getFacturas,
  resetFacturas,
})(Facturas);
